import React from "react";

const HandleErrors = ({ err }) => {
  const erro = err?.response?.data?.message;

  return (
    <>
      <div className="alert alert-danger" role="alert">
        <p>{erro}</p>
      </div>
    </>
  );
};

export default HandleErrors;