import React, {useContext} from "react";

import "./sidebar.css";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {

  const navigate = useNavigate();
  const {dispatch, user} = useContext(AuthContext);

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  return (
    <>
      <aside id="sidebar" className='sidebar'>
        <ul className="sidebar-nav" id="sidebar-nav">
        {user?.userData?.role === "admin" ? (
          <li className="nav-item">
            <a className="nav-link" href={"/" + user?.userData?.role}>
              <i className="bi bi-grid"></i>
              <span>Painel Geral</span>
            </a>
          </li>

        ) : null }

          <li className="nav-heading">Páginas</li>

          <li className="nav-item">
            <a className="nav-link collapsed" href="/admin/agenda">
              <i className="bi bi-calendar"></i>
              <span>Agenda</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" href="/admin/team">
              <i className="bi bi-person-vcard"></i>
              <span>Equipe</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" href="/admin/finance">
              <i className="bi bi-bank"></i>
              <span>Financeiro</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" href="/admin/patient">
              <i className="bi bi-people"></i>
              <span>Pacientes</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" href="/admin/profile">
              <i className="bi bi-person"></i>
              <span>Perfil</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" href="/admin/settings">
              <i className="bi bi-gear"></i>
              <span>Configurações</span>
            </a>
          </li>

          <li className="nav-item" onClick={logout}>
            <a className="nav-link collapsed" href="/logout">
              <i className="bi bi-box-arrow-right"></i>
              <span>Sair</span>
            </a>
          </li>

        </ul>
      </aside>
    </>
  );
};

export default Sidebar;