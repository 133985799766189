import React from "react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import "./about.css";

export default function About() {
  return (
    <>
      <Header />
      <main class="main">
        <Breadcrumb title={"Sobre nós"} href={"/inicio"} />
        <section id="aboutUs" className="aboutUs">
          <div class="container marketing">
            <div class="row featurette">
              <div class="col-md-7">
                <h2 class="featurette-heading">Manuela</h2>
                <p class="lead">
                  Sou pós-graduada em Teoria Psicanalítica e Prática Clínico
                  Institucional e possuo formação em Psicanálise pelo instituto
                  da Escola Brasileira de Psicanálise - Seção Rio. Tenho ampla
                  experiência em atendimentos clínicos e institucionais,
                  oferecendo um espaço seguro e acolhedor para o desenvolvimento
                  pessoal e tratamento de diversas questões emocionais e
                  psicológicas.
                </p>
              </div>
              <div class="col-md-5">
                <img
                  src="/assets/img/about/about-02.webp"
                  alt=""
                  className="image"
                />
              </div>
            </div>

            <hr class="featurette-divider" />

            <div class="row featurette">
              <div class="col-md-7 order-md-2">
                <h2 class="featurette-heading">Anna</h2>
                <p class="lead">
                  Sou pós-graduada em Terapia Cognitivo Comportamental pela
                  Unyleya, pós-graduada em Psicologia Positiva com interação em
                  Coaching pelo CPAF e pesquisadora de transtornos de ansiedade,
                  com foco em síndrome do pânico. Minha abordagem é centrada no
                  indivíduo, utilizando técnicas baseadas em evidências para
                  ajudar meus pacientes a superarem desafios e alcançarem um
                  bem-estar emocional sustentável.
                </p>
              </div>
              <div class="col-md-5 order-md-1">
                <img
                  src="/assets/img/about/about-01.webp"
                  alt=""
                  className="image"
                />
              </div>
            </div>

            <hr class="featurette-divider" />

            <div class="row featurette">
              <div class="col-md-7">
                <h2 class="featurette-heading">Nossa missão</h2>
                <p class="lead">
                  Nossa missão é proporcionar um atendimento psicológico de
                  qualidade, promovendo o bem-estar e o desenvolvimento pessoal
                  de nossos pacientes. Com uma equipe de profissionais
                  qualificados e diversas abordagens terapêuticas, buscamos
                  atender às necessidades individuais de cada paciente, criando
                  um ambiente de confiança e respeito. Estamos comprometidos em
                  oferecer um suporte contínuo e eficaz para a saúde mental de
                  todos que procuram nossos serviços.
                </p>
              </div>
              <div class="col-md-5">
                <img
                  src="/assets/img/about/about-03.webp"
                  alt=""
                  className="image"
                />
              </div>
            </div>

          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
