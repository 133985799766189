import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./home.css";
import AOS from "aos";

import { Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import CountUp from "react-countup";

export default function Home() {
  useEffect(() => {
    const handleScroll = () => {
      AOS.init({ duration: 1000 });
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Header />

      <main className="main">
        <section id="about" className="about section">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div
                className="col-lg-6 mb-5 mb-lg-0 order-lg-2"
              >
                <Swiper
                  className="init-swiper swiper"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                  modules={[Pagination, A11y]}
                  spaceBetween={20}
                  slidesPerView="auto"
                  slidesPerGroup={1}
                  pagination={{
                    clickable: true,
                    el: ".swiper-pagination",
                    type: "bullets",
                  }}
                >
                  <div className="swiper-wrapper">
                    <SwiperSlide>
                      <img
                        src="/assets/img/hero-1.webp"
                        alt="Image1"
                        className="w-100 img-fluid"
                        loading="eager"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src="/assets/img/hero-2.webp"
                        alt="Image2"
                        className="w-100 img-fluid"
                        loading="lazy"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src="/assets/img/hero-3.webp"
                        alt="Image3"
                        className="w-100 img-fluid"
                        loading="lazy"
                      />
                    </SwiperSlide>
                  </div>
                  <div className="swiper-pagination"></div>
                </Swiper>
              </div>
              <div className="col-lg-6 order-lg-1">
                <h1 className="mb-4" data-aos="fade-up">
                  Como você está se sentindo hoje?
                </h1>
                <p data-aos="fade-up">
                  Estamos preparadas para oferecer um espaço seguro e acolhedor
                  onde você pode expressar livremente seus sentimentos e
                  emoções. Entendemos que cada jornada emocional é única e
                  estamos prontas para ouvir, apoiar e te ajudar a construir
                  novas possibilidades na sua vida.
                </p>
                <p className="mt-5" data-aos="fade-up">
                  <a
                    href="https://wa.me/552166637595"
                    className="btn btn-get-started"
                  >
                    Agendar consulta
                  </a>
                </p>
                <div className="row mb-5 count-numbers">
                  <div
                    className="col-4 counter"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <CountUp
                      start={0}
                      end={16}
                      duration={5}
                      className="purecounter number"
                    />
                    <span className="d-block">Psicólogas</span>
                  </div>
                  <div
                    className="col-4 counter"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <CountUp
                      start={0}
                      end={334}
                      duration={5.5}
                      className="purecounter number"
                    />
                    <span className="d-block">Pacientes em atendimento</span>
                  </div>
                  <div
                    className="col-4 counter"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <CountUp
                      start={0}
                      end={4}
                      duration={6}
                      className="purecounter number"
                    />
                    <span className="d-block">Salas para atendimento</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="services" className="services section light-background">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="row mx-auto">
                  <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                    <div
                      className="services-item"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <div className="services-icon">
                        <img
                          src="/assets/img/insurence/amil.png"
                          alt="Aceitamos o Plano Amil"
                          width={"100%"}
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                    <div
                      className="services-item"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="services-icon">
                        <img
                          src="/assets/img/insurence/golden-cross.png"
                          alt="Aceitamos o Plano Golden Cross"
                          width={"100%"}
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                    <div
                      className="services-item"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <div className="services-icon">
                        <img
                          src="/assets/img/insurence/care-plus.png"
                          alt="Aceitamos o Plano Care Plus"
                          width={"100%"}
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                    <div
                      className="services-item"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <div className="services-icon">
                        <img
                          src="/assets/img/insurence/med-senior.png"
                          alt="Aceitamos o Plano Med Senior"
                          width={"100%"}
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="call-to-action" className="call-to-action section">
          <img src="/assets/img/sala.jpg" alt="Foto da sala de atendimento" />

          <div className="container">
            <div
              className="row aos-init aos-animate"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="col-xl-9 text-center text-xl-start">
                <h3>Nosso espaço</h3>
                <p>
                  Pensamos em um lugar confortável e adequado para melhor
                  atendimento.
                </p>
                <a href="/place" className="btn btn-call">
                  Ver mais
                </a>
              </div>
            </div>
          </div>
        </section>
        <section id="team" className="team section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Fundadoras</h2>
              <p>Conheça um pouco sobre as fundadoras da PSI</p>
            </div>

            <div className="row">
              <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img
                      src="/assets/img/manuela.png"
                      className="img-fluid"
                      alt="Foto da sócia Manuela"
                      width={"100%"}
                      loading="lazy"
                    />
                  </div>
                  <div className="member-info">
                    <h4>Manuela</h4>
                    <span>Sócia</span>
                    <p>
                      Sou pós-graduada em Teoria Psicanalítica e Prática Clínico
                      Institucional e possuo formação em Psicanálise pelo
                      instituto da Escola Brasileira de Psicanálise - Seção Rio.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 mt-4 mt-lg-0"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <div className="member d-flex align-items-start">
                  <div className="pic">
                    <img
                      src="/assets/img/anna.png"
                      className="img-fluid"
                      alt="Foto da sócia Anna"
                      width={"100%"}
                      loading="lazy"
                    />
                  </div>
                  <div className="member-info">
                    <h4>Anna</h4>
                    <span>Sócia</span>
                    <p>
                      Sou pós-graduada em Terapia Cognitivo Comportamental pela
                      Unyleya, pós-graduada em Psicologia Positiva com interação
                      em Coaching pelo CPAF e pesquisadora de transtornos de
                      ansiedade, com foco em síndrome do pânico.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="contact section light-background">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14689.262750357582!2d-43.44879449575664!3d-23.012178353998948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9bdd2441e1466b%3A0x27c9a40d582f10bd!2sAv.%20das%20Am%C3%A9ricas%2C%2016.313%20-%20208%20-%20Recreio%20dos%20Bandeirantes%2C%20Rio%20de%20Janeiro%20-%20RJ%2C%2022640-100!5e0!3m2!1spt-BR!2sbr!4v1724070321228!5m2!1spt-BR!2sbr"
            width="100%"
            height="300px"
            style={{ border: 0, margin: "40px 0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="Mapa"
          />

          <div className="container">
            <div className="content">
              <div className="row justify-content-center">
                <div
                  className="col-md-11 col-lg-11"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="row">
                    <div className="col-lg-4">
                      <div
                        className="service no-shadow link horizontal d-flex active"
                        data-aos="fade-left"
                        data-aos-delay="0"
                      >
                        <div className="service-icon color-1 mb-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-geo-alt-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                          </svg>
                        </div>

                        <div className="service-contents">
                          <p>
                            Av. das américas 16.285, sala 208 - Recreio dos
                            Bandeirantes.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div
                        className="service no-shadow link horizontal d-flex active"
                        data-aos="fade-left"
                        data-aos-delay="0"
                      >
                        <div className="service-icon color-1 mb-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-envelope-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"></path>
                          </svg>
                        </div>

                        <div className="service-contents">
                          <p>psiescutaterapeutica@hotmail.com</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div
                        className="service no-shadow link horizontal d-flex active"
                        data-aos="fade-left"
                        data-aos-delay="0"
                      >
                        <div className="service-icon color-1 mb-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-telephone-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                            ></path>
                          </svg>
                        </div>

                        <div className="service-contents">
                          <p>+55 21 96663 - 7595</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <form
                  action=""
                  method="post"
                  className="row justify-content-center mt-0 gy-4 php-email-form"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  hidden
                >
                  <h2 className="content-title">Fale conosco</h2>

                  <div className="col-lg-8">
                    <div className="row g-4 mb-4">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Seu nome completo"
                            className="form-control"
                            id="fname"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row g-4">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            type="tel"
                            placeholder="(12) 3.4567-8910"
                            className="form-control"
                            id="tel"
                          />
                        </div>
                      </div>

                      <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">
                          Your message has been sent. Thank you!
                        </div>
                      </div>
                      <div className="mt-0">
                        <button type="submit" className="btn-submit">
                          Enviar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}
