import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { maskBr } from "js-brasil";

import "./dashboard.css";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Admin/Sidebar/Sidebar";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import HandleErrors from "../../../components/HandleErrors/HandleErrors";

import { cleanPhoneNumber } from "../../../utils/utils";
import { getData } from "../../../utils/data";

const Dashboard = ({ title, thead, service, itemsPerPage }) => {
  const [serviceData, setServiceData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getData(serviceData, setServiceData, setIsLoading, setError);
  }, [serviceData]);

  const filteredData = serviceData?.filter((user) =>
    user?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(filteredData?.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredData?.slice(offset, offset + itemsPerPage);

  return (
    <div className="Dashboard">
      <Header />
      <Sidebar />

      <main className="main" id="main">
        <Breadcrumb title={title} href={"/admin"} />

        <section className="admin dashboard" style={{ minHeight: "100vh" }}>
          <div className="container">
            <div className="content">
              <div className="row justify-content-center">
                <div className="col-xxl-2 col-md-6"></div>
              </div>

              <div className="col-9">
                <div className="card recent-sales">
                  <div className="filter">
                    <a className="icon" href={`/admin/${service}/new`}>
                      <i
                        className="bi bi-plus-square-fill"
                        style={{ fontSize: "24px" }}
                      ></i>
                    </a>
                  </div>
                  <div className="card-body overflow-auto">
                    <h5 className="card-title">
                      {title} <span>| Total</span>
                    </h5>

                    <form className="row g-3 mt-4 mb-4 w-50 align-items-center">
                      <div className="col-12">
                        <label className="form-label">Buscar </label>
                        <div className="input-group">
                          <input
                            className="form-control"
                            name="user"
                            placeholder="Buscar por nome"
                            required
                            onChange={(e) => setSearchTerm(e.target.value)}
                            id="user"
                          />
                        </div>
                      </div>
                    </form>

                    {isLoading ? (
                      <div>Loading...</div>
                    ) : error ? (
                      <HandleErrors err={error}></HandleErrors>
                    ) : (
                      <>
                        <table className="table table-borderless datatable">
                          <thead className="table-dark">
                            <tr>
                              <th scope="col">#</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems?.map((data, index) => (
                              <tr key={data._id}>
                                <th scope="row">{offset + index + 1}</th>
                                <td>{data?.name}</td>
                                <td>{maskBr.celular(data?.tel)}</td>
                                <td>{data?.email}</td>
                                <td>{maskBr.cpf(data?.identity)}</td>
                                <td>{data?.updatedBy}</td>
                                <td>{data?.status}</td>
                                <td>
                                  <a
                                    className="icon btn btn-primary"
                                    href="/admin"
                                    data-bs-toggle="dropdown"
                                  >
                                    <i className="bi bi-three-dots"></i>
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow filter">
                                    <li className="dropdown-header text-start">
                                      <h6>Ação</h6>
                                    </li>
                                    {data?.tel ? (
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href={`https://wa.me/55${cleanPhoneNumber(
                                            data?.tel
                                          )}`}
                                        >
                                          Mensagem
                                        </a>
                                      </li>
                                    ) : (
                                      <></>
                                    )}
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        href={`/admin/${service}/${data._id}`}
                                      >
                                        Ver
                                      </a>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <ReactPaginate
                          previousLabel={"← Anterior"}
                          nextLabel={"Próximo →"}
                          pageCount={pageCount}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                        />
                        <span className="pagination-count">
                          Página {currentPage + 1} de {pageCount}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Dashboard;
