import React from "react";
import Header from "../../components/Header/Header";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Footer from "../../components/Footer/Footer";

export default function Place() {
  return (
    <>
      <Header />
      <main class="main">
        <Breadcrumb title={"Nosso espaço"} href={"/inicio"} />

        <section id="portfolio" class="portfolio section">
          <div class="container">
            <div
              class="isotope-layout"
              data-default-filter="*"
              data-layout="masonry"
              data-sort="original-order"
            >
              <div
                class="row gy-4 isotope-container"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
                  <a
                    href="/assets/img/clinic/clinic-01.webp"
                    data-gallery="portfolio-gallery-product"
                    class="glightbox preview-link"
                  >
                    <img
                      src="/assets/img/clinic/clinic-01.webp"
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
                  <a
                    href="/assets/img/clinic/clinic-02.webp"
                    data-gallery="portfolio-gallery-product"
                    class="glightbox preview-link"
                  >
                    <img
                      src="/assets/img/clinic/clinic-02.webp"
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
                  <a
                    href="/assets/img/clinic/clinic-03.webp"
                    data-gallery="portfolio-gallery-product"
                    class="glightbox preview-link"
                  >
                    <img
                      src="/assets/img/clinic/clinic-03.webp"
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
                  <a
                    href="/assets/img/clinic/clinic-04.webp"
                    data-gallery="portfolio-gallery-product"
                    class="glightbox preview-link"
                  >
                    <img
                      src="/assets/img/clinic/clinic-04.webp"
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>

                <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">
                  <a
                    href="/assets/img/clinic/clinic-05.webp"
                    data-gallery="portfolio-gallery-product"
                    class="glightbox preview-link"
                  >
                    <img
                      src="/assets/img/clinic/clinic-05.webp"
                      class="img-fluid"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
