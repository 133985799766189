export const cleanPhoneNumber = (phoneNumber) => {
  return phoneNumber.replace(/\D/g, "").replace(/\s/g, "");
};

export const select = (el, all = false) => {
  el = el.trim();
  if (all) {
    return [...document.querySelectorAll(el)];
  } else {
    return document.querySelector(el);
  }
};

export const on = (type, el, listener, all = false) => {
  let selectEl = select(el, all);
  if (selectEl) {
    if (all) {
      selectEl.forEach((e) => e.addEventListener(type, listener));
    } else {
      selectEl.addEventListener(type, listener);
    }
  }
};

export const onscroll = (el, listener) => {
  el.addEventListener("scroll", listener);
};

export const offscroll = (element, eventName, handler) => {
  if (element && element.removeEventListener) {
    element.removeEventListener(eventName, handler);
  }
};

export const BASE_URL = {
  key: "http://localhost:4001/",
};
