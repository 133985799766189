import React from "react";

export default function Breadcrumb({ title, href }) {
  return (
    <>
      <div class="page-title light-background">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-lg-12">
              <h1 class="page-title-heading">{title}</h1>
              <nav aria-label="breadcrumb">
                <ol class="custom-breadcrumb breadcrumb">
                  <li class="breadcrumb-item">
                    <a href={href}>Início</a>
                  </li>
                  <li
                    class="breadcrumb-item active current"
                    aria-current="page"
                  >
                    {title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
