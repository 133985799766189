import React, { useState } from "react";
import Modal from "react-modal";
import "./person.css";
Modal.setAppElement("#root");

const personStyle = {
  span: {
    color: "#b38f8f",
  },
  h2: {
    fontWeight: "700",
    fontSize: "3rem",
    color: "#b38f8f",
  },
};

export default function Person({ src, alt, href, title, nome, funcao, sobre, especializacao }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div class="team">
        <div class="pic">
          <img src={src} alt={alt} class="img-fluid" width={"100%"} />
        </div>
        <h3>
          <span style={personStyle.span}>{nome}</span>
        </h3>
        <span class="d-block position">{funcao}</span>
        <p>{sobre}</p>
        <p class="mb-0">
          <button class="more dark btn" onClick={openModal}>
            Currículo <span class="bi bi-arrow-right-short"></span>
          </button>
        </p>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Currículo"
        style={{ position: "absolute", zIndex: 999 }}
      >
        <div id="about" class="about" style={{ padding: "30px 0" }}>
          <div
            class="container section-title aos-init aos-animate"
            data-aos="fade-up"
          >
            <h2 style={personStyle.h2}>Sobre mim</h2>
            <p>Conheça um pouco sobre meu trabalho.</p>
          </div>

          <div
            class="container aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div
              class="row gy-4 justify-content-center"
              style={{ backgroundColor: "#e7ddd9", borderRadius: "10px" }}
            >
              <div class="col-lg-4" style={{ marginBottom: "1.5rem" }}>
                <img
                  src={src}
                  class="img-fluid"
                  alt={alt}
                  style={{ borderRadius: "100%" }}
                  width={"100%"}
                />
              </div>
              <div class="col-lg-8 content">
                <h2 style={personStyle.h2}>
                  {nome}
                </h2>
                <p class="fst-italic py-3">{title}</p>
                <div class="row">
                  <div class="col-lg-6">
                    <ul>
                      {especializacao?.map((data, index) => (
                        <li>
                          <i class="bi bi-chevron-right"></i> <span>{data}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <p class="py-3">{sobre}</p>
              </div>
            </div>
          </div>
        </div>

        <button
          onClick={closeModal}
          className="btn btn-secondary"
          style={{ marginTop: "20px", marginLeft: "10px" }}
        >
          Voltar
        </button>
      </Modal>
    </>
  );
}
