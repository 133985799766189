import React from "react";
import "./team.css";
import { Pagination, A11y, Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import Header from "../../components/Header/Header";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Footer from "../../components/Footer/Footer";
import Person from "../../components/Team/Person";

export default function Team() {

  return (
    <>
      <Header />
      <main class="main">
      <Breadcrumb title={"Equipe"} href={"/inicio"} />

        <section id="team" class="team section">
          <div class="site-section slider-team-wrap">
            <div class="container">
              <div class="slider-nav d-flex justify-content-end mb-3">
                <button class="js-prev js-custom-prev">
                  <i class="bi bi-arrow-left-short"></i>
                </button>
                <button class="js-next js-custom-next">
                  <i class="bi bi-arrow-right-short"></i>
                </button>
              </div>

              <Swiper
                className="init-swiper swiper"
                data-aos="zoom-in"
                data-aos-delay="100"
                modules={[Pagination, A11y, Autoplay, Navigation]}
                loop={true}
                speed={600}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                spaceBetween={20}
                pagination={{
                  clickable: true,
                  el: ".swiper-pagination",
                  type: "bullets",
                }}
                navigation={{
                  nextEl: ".js-custom-next",
                  prevEl: ".js-custom-prev",
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: { 
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: { 
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
              >
                <div class="swiper-wrapper">
                  <SwiperSlide> <Person src={"/assets/img/team/Lucia Barreiro.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Lúcia Caridade Barreiro"} about={""} title={"Graduada em psicologia pela Universidade Federal do Rio de Janeiro"} especializacao={["Psicologia clínica com foco em Psicanálise"]} /> </SwiperSlide>
                  <SwiperSlide> <Person src={"/assets/img/team/Jessica Pinheiro.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Jessica de Souza Pinheiro"} about={""} title={"Bacharel em Psicologia - UNESA"} especializacao={["Terapia cognitivo comportamental", "Avaliação Neuropsicológica"]} /> </SwiperSlide>
                  <SwiperSlide> <Person src={"/assets/img/team/Gabriella Dominguez.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Gabriella Pinho Martinez Dominguez"} about={""} title={"Bacharel em Psicologia"} especializacao={["Psicologia clinica e saude coletiva"]} /> </SwiperSlide>
                  <SwiperSlide> <Person src={"/assets/img/team/Maria Andréa Costa Lino Vianna.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Maria Andréa da Costa Lino Vianna"} about={""} title={""} especializacao={["Psicologia clínica com foco em psicanálise"]} /> </SwiperSlide>
                  <SwiperSlide> <Person src={"/assets/img/team/Fernanda Dallaruvera.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Fernanda Alves Dallaruvera"} about={""} title={"Graduação em Psicologia pela IBMR"} especializacao={["Terapia Cognitivo Comportamental"]} /> </SwiperSlide>
                  <SwiperSlide> <Person src={"/assets/img/team/Laura Andrade.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Laura Andrade da Silva"} about={""} title={"Bacharel em psicologia"} especializacao={["Formação em Psicanálise", "Especialização em Psicologia Jurídica", "Especialização em Psico - Oncologia"]} /> </SwiperSlide>
                  <SwiperSlide> <Person src={"/assets/img/team/Bruna Lumer.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Bruna Oliveira Lumer"} about={""} title={"Graduação em psicologia pela UFRJ"} especializacao={[]} /> </SwiperSlide>
                  <SwiperSlide> <Person src={"/assets/img/team/Fernanda Ramos.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Fernanda Ramos"} about={""} title={""} especializacao={["Psicologia Clínica como Gestalt Terapeuta"]} /> </SwiperSlide>
                  <SwiperSlide> <Person src={"/assets/img/team/Isadora Teixeira.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Isadora de Jesus Teixeira"} about={""} title={""} especializacao={["Pós-graduanda em psicologia clínica, pelo olhar da psicanálise"]} /> </SwiperSlide>
                  <SwiperSlide> <Person src={"/assets/img/team/Stephanie Rodrigues.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Stephanie Rodrigues dos Santos"} about={""} title={""} especializacao={["Psicologia clínica com foco em Terapia Cognitivo-Comportamental"]} /> </SwiperSlide>
                  <SwiperSlide> <Person src={"/assets/img/team/Vanussa Paiva.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Vanussa de Souza Paiva"} about={""} title={""} especializacao={["Especialista em Terapia Cognitivo Comportamental"]} /> </SwiperSlide>
                  <SwiperSlide> <Person src={"/assets/img/team/Roberta Nunes.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Roberta Juliana de Souza Nunes"} about={""} title={""} especializacao={["Psicologia clínica com foco em sexualidade"]} /> </SwiperSlide>
                  <SwiperSlide> <Person src={"/assets/img/team/Meiry Veras.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Meiry Veras de Sousa Israel"} about={""} title={""} especializacao={["Especialização em TCC(Terapia cognitivo comportamental)"]} /> </SwiperSlide>
                  <SwiperSlide> <Person src={"/assets/img/team/Lai.webp"} alt={"Image"} funcao={"Psicóloga"} href={"#"} nome={"Lailanna Guedes Gomes"} about={""} title={""} especializacao={["Neuropsicologia"]} /> </SwiperSlide>
                </div>
              </Swiper>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
