import React from "react";

export default function Footer() {
  return (
    <footer class="footer light-background">
      <div class="container">
        <div class="row g-4">
          <div class="col-md-6 col-lg-3 mb-3 mb-md-0">
            <div class="widget">
              <h3 class="widget-heading">Sobre nós</h3>
              <p class="mb-4">
                Estamos preparadas para oferecer um espaço seguro e acolhedor
                onde você pode expressar livremente seus sentimentos e emoções.
                Entendemos que cada jornada emocional é única e estamos prontas
                para ouvir, apoiar e te ajudar a construir novas possibilidades
                na sua vida.
              </p>
              <p class="mb-0">
                <a href="/about" class="btn-learn-more">
                  Saiba mais
                </a>
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 ps-lg-5 mb-3 mb-md-0">
            <div class="widget">
              <h3 class="widget-heading">Páginas</h3>
              <ul class="list-unstyled float-start me-5">
                <li>
                  <a href="/">Início</a>
                </li>
                <li>
                  <a href="/about">Sobre nós</a>
                </li>
                <li>
                  <a href="/team">Equipe</a>
                </li>
                <li>
                  <a href="/place">Nosso espaço</a>
                </li>
                <li>
                  <a href="https://wa.me/552166637595">Contato</a>
                </li>
                <li>
                  <a href="/login">Login</a>
                </li>
              </ul>
            </div>
          </div>
          {/* <div class="col-md-6 col-lg-3 pl-lg-5">
            <div class="widget">
              <h3 class="widget-heading">Posts recentes</h3>
              <ul class="list-unstyled footer-blog-entry">
                <li>
                  <span class="d-block date">3 de maio de 2020</span>
                  <a href="#">
                    Compreendendo o Autismo: Abordagens terapêuticas estão sendo
                    exploradas e implementadas com sucesso
                  </a>
                </li>
                <li>
                  <span class="d-block date">3 de maio de 2020</span>
                  <a href="#">
                    Leitura em voz alta para crianças pode aumentar suas
                    habilidades linguísticas e cognitivas
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          <div class="col-md-6 col-lg-3 pl-lg-5">
            <div class="widget">
              <h3 class="widget-heading">Redes Sociais</h3>
              <ul class="list-unstyled social-icons light mb-3">
                <li>
                  <a href="https://wa.me/552166637595">
                    <span class="bi bi-whatsapp"></span>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/psi.escutaterapeutica/">
                    <span class="bi bi-instagram"></span>
                  </a>
                </li>
                <li>
                  <a href="mailto:psiescutaterapeutica@hotmail.com">
                    <span class="bi bi-google"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
