import React from "react";
import "./admin.css";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Admin/Sidebar/Sidebar";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const Admin = () => {
  return (
    <>
      <Header />
      <Sidebar />

      <main className="main" id="main">
        <Breadcrumb title={"Dashboard"} href={"/admin"} />

        <section id="admin" className="admin" style={{minHeight: "100vh"}}>
          <div className="container">
            <div className="content">
              <div className="row justify-content-center">
                <div className="col-xxl-2 col-md-6"></div>
              </div>

              <div className="col-9"></div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Admin;
