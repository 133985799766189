import React from "react";
import Header from "../../components/Header/Header";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Footer from "../../components/Footer/Footer";

export default function Contact() {
  return (
    <>
      <Header />
      <main className="main">
        <Breadcrumb title={"Contato"} href={"/inicio"} />
        <section className="contact" id="contact" style={{marginTop:0}}>
          <div className="container">
            <div class="content">
              <div class="row justify-content-center">
                <div
                  class="col-md-8 col-lg-8"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div class="row">
                    <div class="col-lg-4">
                      <div
                        class="service no-shadow link horizontal d-flex active"
                        data-aos="fade-left"
                        data-aos-delay="0"
                      >
                        <div class="service-icon color-1 mb-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-geo-alt-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                          </svg>
                        </div>

                        <div class="service-contents">
                          <p>Endereço</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div
                        class="service no-shadow link horizontal d-flex active"
                        data-aos="fade-left"
                        data-aos-delay="0"
                      >
                        <div class="service-icon color-1 mb-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-envelope-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"></path>
                          </svg>
                        </div>

                        <div class="service-contents">
                          <p>Email</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div
                        class="service no-shadow link horizontal d-flex active"
                        data-aos="fade-left"
                        data-aos-delay="0"
                      >
                        <div class="service-icon color-1 mb-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-telephone-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                            ></path>
                          </svg>
                        </div>

                        <div class="service-contents">
                          <p>Telefone</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <form
                  action=""
                  method="post"
                  class="row justify-content-center mt-0 gy-4 php-email-form"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <h2 class="content-title">Fale conosco</h2>

                  <div class="col-lg-8">
                    <div class="row g-4 mb-4">
                      <div class="col-12">
                        <div class="form-group">
                          <input
                            type="text"
                            placeholder="Seu nome completo"
                            class="form-control"
                            id="fname"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row g-4">
                      <div class="col-12">
                        <div class="form-group">
                          <input
                            type="tel"
                            placeholder="(12) 3.4567-8910"
                            class="form-control"
                            id="tel"
                          />
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form-group">
                          <input
                            type="email"
                            placeholder="exemplo@exemplo.com"
                            class="form-control"
                            id="email"
                          />
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="form-group">
                          <textarea
                            class="form-control"
                            id="text"
                            placeholder="Mensagem"
                            rows={5}
                          />
                        </div>
                      </div>

                      <div class="my-3">
                        <div class="loading">Loading</div>
                        <div class="error-message"></div>
                        <div class="sent-message">
                          Your message has been sent. Thank you!
                        </div>
                      </div>
                      <div class="mt-0">
                        <button type="submit" class="btn-submit">
                          Enviar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
