import React from "react";
import "./auth.css";

export default function Login() {
  return (
    <>
      <main class="main">
        <section className="section" style={{ height: "100vh" }}>
          <div className="container h-100">
            <div className="row form-signin w-100 m-auto h-100">
              <div className="d-flex justify-content-center align-items-center">
                <form>
                  <h1 class="h3 mb-3 fw-normal text-center" style={{color:"#b38f8f", fontSize: 40}}>Entrar</h1>

                  <div class="form-floating">
                    <input
                      type="email"
                      class="form-control"
                      id="floatingInput"
                      placeholder="name@example.com"
                    />
                    <label for="floatingInput">Email</label>
                  </div>
                  <div class="form-floating">
                    <input
                      type="password"
                      class="form-control"
                      id="floatingPassword"
                      placeholder="Password"
                    />
                    <label for="floatingPassword">Password</label>
                  </div>

                  <button class="btn w-100 py-2 btn-principal" type="submit">
                    Entrar
                  </button>
                  <p class="mt-5 mb-3 text-body-secondary">&copy; 2024</p>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
