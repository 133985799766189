import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import { AuthContext } from "../context/AuthContext";


import Home from "../pages/Home/Home";
import EmBreve from "../pages/EmBreve/EmBreve";
import About from "../pages/About/About";
import Team from "../pages/Team/Team";
import Place from "../pages/Place/Place";
import Contact from "../pages/Contact/Contact";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import NotFoundPage from "./../pages/NotFoundPage/NotFoundPage";
import Admin from "../pages/Admin/Admin";
import AdminTeam from "../pages/Admin/Team/AdminTeam";

const Private = ({ Item }) => {
  const { user } = useContext(AuthContext);
  const token = Cookies.get("accessToken");

  var signed = undefined;
  if (user) {
    if (token) {
      if (user.userData.role === "admin") {
        signed = 1;
      }
    }
  } else {
    signed = 0;
  }

  return signed === 1 ? <Item /> : <Login />;
};

const Routers = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<EmBreve />} /> */}
      <Route path="/" element={<Home />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/admin/team" element={<AdminTeam />} />
      <Route path="/about" element={<About />} />
      <Route path="/team" element={<Team />} />
      <Route path="/place" element={<Place />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Routers;