import React, { useEffect } from "react";
import { select } from "../../utils/utils";

export default function Header() {
  useEffect(() => {
    const toggleButton = select(".mobile-nav-toggle");
    const header = select("#header");

    const handleToggle = (e) => {
      header.classList.toggle("mobile-nav-active");
      toggleButton.classList.toggle("bi-list");
      toggleButton.classList.toggle("bi-x");
    };

    if (toggleButton && header) {
      toggleButton.addEventListener("click", handleToggle);
    }

    return () => {
      if (toggleButton && header) {
        toggleButton.removeEventListener("click", handleToggle);
      }
    };
  }, []);

  return (
    <header id="header" className="header d-flex align-items-center sticky-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center">
          <img src="assets/img/logo.jpeg" alt="Logo da PSI" width={"100%"}/>
        </a>

        <nav id="navmenu" className="navmenu">
          <ul>
            <li>
              <a href="/" className="active">
                Início
              </a>
            </li>
            <li>
              <a href="/about">Sobre nós</a>
            </li>
            <li>
              <a href="/place">Nosso espaço</a>
            </li>
            <li>
              <a href="/team">Equipe</a>
            </li>
            {/* <li>
              <a href="/blog">Blog</a>
            </li> */}
            <li>
              <a href="#contact">Contato</a>
            </li>
            <li>
              <a href="/login">
                <span>Login</span>
                <i className="bi bi-chevron-down toggle-dropdown"></i>
              </a>
            </li>
          </ul>
          <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
        </nav>
      </div>
    </header>
  );
}
